<template>
  <div>
    <h2>{{ "Über Uns" | translate }}</h2>
    <div class="row">
      <div class="col-lg-12">
        <div class="panel p-2">
          <div
            class="panel t1 p-3 mb-2"
            v-for="(item, index) in blogData"
            :key="index"
          >
            <div class="date color">
              <h5>{{ item.date }}</h5>
            </div>
            <div v-html="item.html"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataService from "@/services/DataService.js";
import { mapGetters } from "vuex";

export default {
  name: "app-store",
  data() {
    return {
      blogData: [],
    };
  },
  created() {
    DataService.getStoreBlog().then((response) => {
      this.blogData = response.data;
    });
  },
  computed: {
    ...mapGetters(["companyData"]),
  },
};
</script>
