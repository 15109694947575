<template>
  <b-navbar toggleable="xl" type="dark" sticky>
    <!-- Right aligned nav items -->
    <b-navbar-nav class="mr-auto">
      <b-nav-item>
        <div class="header_table" v-if="companyData.tableNumber" >
          {{ "Tisch" | translate }} {{ tableNumber }} 
          <div v-if="openOrder" class="header_button d-inline-block ml-3" @click="bill()">
            <BaseIcon
              name="credit-card"
              width="24"
              height="24"
              class="header_icon mb-n1"
            />
          </div>
        </div>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">
      <b-nav-item class="header_button d-inline-block">
        <CartWidget class="d-inline-block"/>
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import CartWidget from "@/components/shopping-cart/CartWidget";

export default {
  name: "app-header",
  data() {
    return {
      min_orderValue: 0,
      billTotal: 0
    };
  },
  created: function () {
    this.min_orderValue = this.companyData.minimumOrderValue;
  },
  computed: {
    ...mapGetters(["companyData", "total"]),
    tableNumber() {
      if(this.companyData.tableNumber){
        return this.companyData.tableNumber;
      }else{
        return "";
      }
    },
    openOrder() {
      return this.companyData.hasOpenOrdersPayment;
    },
  },
  components: {
    CartWidget,
  },
  methods: {
    clearStore() {
      window.localStorage.clear();
      window.location.href =
        "/stores/?lang=" + this.companyData.defaultLanguage;
    },
    get_lang() {
      return Vue.i18n.locale();
    },
    set_lang(value) {
      Vue.i18n.set(value);
    },
    bill() {
      if (this.$route.path !== "/table") this.$router.push({ path: "/table" });
    },
  },
};
</script>

<style scoped>
.min_orderValue {
  color: red;
}
.dropdown-item img {
  width: 50px;
  padding: 5px;
}
.submenu {
  position: relative;
  margin-left: 100%;
}
.icon-wrapper {
  color: #ffffff;
}
</style>
