import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router";

import VueCurrencyFilter from "vue-currency-filter";
import VueQrcodeReader from "vue-qrcode-reader";

import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import VueMeta from "vue-meta";
import VueScrollTo from "vue-scrollto";
import VueDragscroll from "vue-dragscroll";

import "./plugins/bootstrap-vue";
import "./assets/css/default.scss";
import "./registerServiceWorker";
import * as Resize from "iframe-resizer";
import IdleVue from "idle-vue";

Vue.use(VueMeta);
Vue.use(Resize);
Vue.use(VueQrcodeReader);
Vue.use(VueScrollTo);
Vue.use(VueDragscroll);
Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "back",
  symbolSpacing: true,
});

Vue.config.productionTip = false;
Vue.prototype.$firstRun16 = true;
Vue.prototype.$firstRun18 = true;

const requireComponent = require.context(
  "./components",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

fetch("/assets/config.json")
  .then((res) => res.json())
  .then((config) => {
    const eventsHub = new Vue();
    Vue.use(IdleVue, {
      eventEmitter: eventsHub,
      store,
      idleTime: config.idleTime,
      startAtIdle: false,
    });
    Vue.prototype.$config = config;
    new Vue({
      router,
      store,
      created() {
        const compString = localStorage.getItem("company");
        if (compString) {
          const compData = JSON.parse(compString);
          this.$store.commit("SET_STORE", compData);
        }
      },
      render: (h) => h(App, { props: { config } }),
    }).$mount("#app");
  });
