import Vue from "vue";
import Router from "vue-router";
import Order from "./views/Order.vue";
import Stores from "./views/Stores.vue";
import Store from "./views/Store.vue";
import Legal from "./views/Legal.vue";
import Privacy from "./views/Privacy.vue";
import Product from "./views/ProductPage.vue";
import Cart from "./views/CartPage.vue";
import Orders from "./views/Orders.vue";
import Table from "./views/Table.vue";
import PaymentSuccess from "./views/PaymentSuccess.vue";
import BillSuccess from "./views/BillSuccess.vue";
import v27gCDJx0G6r from "./views/v27gCDJx0G6r.vue";

const CheckOut = () =>
  import(/* webpackChunkName: "checkout" */ "./views/CheckOut.vue");

const Payment = () =>
  import(/* webpackChunkName: "bill" */ "./views/Payment.vue");

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "order",
      component: Order,
      props: true,
      beforeEnter(to, from, next) {
        if (to.query.reset) {
          window.localStorage.clear();
          next({ name: "stores" });
        } else {
          next();
        }
      },
    },
    {
      path: "/stores",
      name: "stores",
      component: Stores,
      meta: {
        hideNavbarTop: true,
        hideLogin: true,
      },
    },
    {
      path: "/store",
      name: "store",
      component: Store,
      meta: {
        requiresStore: true,
      },
    },
    {
      path: "/legal",
      name: "legal",
      component: Legal,
      meta: {
        requiresStore: true,
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: Privacy,
      meta: {
        requiresStore: true,
      },
    },
    {
      path: "/product/:productId",
      name: "productPage",
      component: Product,
      meta: {
        requiresStore: true,
      },
      props: true,
    },
    {
      path: "/cart",
      name: "CartPage",
      component: Cart,
      meta: {
        requiresStore: true,
      },
      props: true,
    },
    {
      path: "/checkout",
      name: "CheckOut",
      component: CheckOut,
      meta: {
        requiresStore: true,
      },
      props: true,
    },
    {
      path: "/payment",
      name: "Payment",
      component: Payment,
      meta: {
        requiresStore: true,
      },
      props: true,
    },
    {
      path: "/orders",
      name: "orders",
      component: Orders,
      meta: {
        requiresStore: true,
      },
    },
    {
      path: "/table",
      name: "table",
      component: Table,
      meta: {
        requiresStore: true,
      },
    },
    {
      path: "/paysuccess",
      name: "paymentSuccess",
      component: PaymentSuccess,
      meta: {
        requiresStore: true,
      },
    },
    {
      path: "/billsuccess",
      name: "billSuccess",
      component: BillSuccess,
      meta: {
        requiresStore: true,
      },
    },
    {
      path: "/v27gCDJx0G6r",
      name: "v27gCDJx0G6r",
      component: v27gCDJx0G6r,
    },
  ],
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {});
};

router.beforeEach((to, from, next) => {
  const inStore = localStorage.getItem("company");
  if (to.matched.some((record) => record.meta.requiresStore) && !inStore) {
    next({ name: "stores" });
  } else {
    next();
  }
});

export default router;
