<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="cart panel t1 p-2">
        <ShoppingCartSummary class="mb-2" />

        <div v-if="cartProducts.length">
          <div class="cbbl panel t1 px-1 pt-1 mb-2">
            <ShoppingCartItem
              v-for="sub in cartProducts"
              :cartItem="sub"
              :key="sub.index"
            />
          </div>

          <div class="d-flex justify-content-between mb-2">
            <div class="m-0">
              <button
                class="
                  btn btn-md btn-secondary
                  m-0
                  d-none d-md-block d-sm-block d-lg-none
                "
                @click="goBack()"
              >
                {{ "Abbrechen" | translate }}
              </button>
            </div>
            <div class="m-0 p-0">
              <button class="btn btn-md btn-secondary m-0" @click="emptyCart">
                {{ "Leeren" | translate }}
              </button>
            </div>
          </div>

          <button
            class="btn btn-lg btn-primary btn-block m-0"
            @click="checkOut"
          >
            {{ "Kasse" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShoppingCartItem from "@/components/shopping-cart/ShoppingCartItem";
import ShoppingCartSummary from "@/components/shopping-cart/ShoppingCartSummary";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
  name: "ShoppingCart",
  computed: {
    ...mapGetters(["cartProducts", "companyData", "total"]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
  },
  components: {
    ShoppingCartItem,
    ShoppingCartSummary,
  },
  data() {
    return {
      min_orderValue: 0,
    };
  },
  created() {
    this.getProducts({ key: this.companyData.mid });
    this.min_orderValue = this.companyData.minimumOrderValue;
  },
  methods: {
    ...mapActions(["getProducts", "removeAllFromCart"]),
    emptyCart() {
      Vue.prototype.$firstRun16 = true;
      Vue.prototype.$firstRun18 = true;
      this.removeAllFromCart();
      this.goBack();
    },
    goBack() {
      if (this.$route.matched.some(({ name }) => name === "order")) {
        null;
      } else {
        setTimeout(() => {
          this.$router.push({ name: "order" });
        }, 300);
      }
    },
    checkOut() {
      setTimeout(() => {
        this.$router.push({ name: "CheckOut" });
      }, 300);
    },
  },
  watch: {
    total(val) {
      if (val == 0) {
        if (this.$route.path === "/cart") this.$router.push({ path: "/" });
      }
    },
  },
};
</script>
<style scoped>
.min_orderValue {
  color: red;
  font-size: 20px;
}
</style>
