const state = {
  all: [],
  added: [],
};

const mutations = {
  RECEIVE_PRODUCTS(state, products) {
    state.all = products;
  },

  ADD_TO_CART(state, item) {
    state.added.push({
      nr: item.nr,
      id: item.id,
      totalPrice: item.totalPrice,
      selectedOptions: item.selectedOptions,
      productQuantity: item.productQuantity,
      productNote: item.productNote,
      productCourse: item.productCourse,
    });
  },

  PRODUCT_TOTAL(state, payload) {
    state.productTotal = payload;
  },

  REMOVE_FROM_CART(state, item) {
    const index = state.added.findIndex((added) => added.nr === item.nr);
    state.added.splice(index, 1);
  },

  REPLACE_IN_CART(state, item) {
    const index = state.added.findIndex((added) => added.nr === item.nr);
    state.added.splice(index, 1, item);
  },

  REMOVE_ALL_FROM_CART(state) {
    var cartItems = state.added;
    for (var i = 0; i < cartItems.length; i++) {
      const index = state.added.findIndex(
        (added) => added.nr === cartItems[i].nr
      );
      state.added.splice(index, 1);
      i = -1;
    }
  },
};

export default {
  state,
  mutations,
};
