const state = {
  company: [],
};

const mutations = {
  SET_STORE(state, storeData) {
    localStorage.removeItem("company");
    localStorage.setItem("company", JSON.stringify(storeData));
    state.company = storeData;
  },

  CLEAR_STORE() {
    state.company = 0;
    localStorage.removeItem("company");
  },
};

export default {
  state,
  mutations,
};
