<template>
  <div v-if="companyData.length != 0">
    <b-modal ref="busy-message" id="busy-message" hide-footer hide-header>
      <div v-html="messageOfToday"></div>
      <button
        class="btn btn-md btn-secondary mx-0 mt-3 btn-block"
        @click="$bvModal.hide('busy-message')"
      >
        {{ "Okay" | translate }}
      </button>
    </b-modal>
    <closed v-if="onDuty != 'open'" />
    <orderView v-if="onDuty == 'open'" />
  </div>
</template>

<script>
import orderView from "@/components/orderView.vue";
import closed from "@/components/closed.vue";
import { mapGetters } from "vuex";
import Vue from "vue";
import axios from "axios";

export default {
  name: "app-order",
  components: {
    orderView,
    closed,
  },
  computed: {
    ...mapGetters(["companyData"]),
    onDuty() {
      return this.companyData.onDuty;
    },
    inStore() {
      const inStore = localStorage.getItem("company");
      if (inStore && inStore != 0) {
        return true;
      } else {
        return false;
      }
    },
    messageOfToday() {
      return this.companyData.messageOfToday;
    },
  },
  methods: {
    initLang() {
      this.companyData.languages.forEach(function (index) {
        if (index != "") {
          axios
            .get("/locale/" + index + ".json?v=" + Date.now() + "")
            .then((response) => {
              Vue.i18n.add(index, response.data);
            });
        }
      });
      if (this.$route.query.lang) {
        Vue.i18n.set(this.$route.query.lang);
      } else {
        if (!Vue.i18n.locale()) {
          Vue.i18n.set(this.companyData.defaultLanguage);
        }
      }
    },
    showModal() {
      this.$refs["busy-message"].show();
    },
  },
  created() {
    if (this.$route.query.lang) {
      Vue.i18n.set(this.$route.query.lang);
    }
    if (this.inStore) {
      this.companyData.languages.forEach(function (index) {
        if (index != "") {
          axios
            .get("/locale/" + index + ".json?v=" + Date.now() + "")
            .then((response) => {
              Vue.i18n.add(index, response.data);
            });
        }
      });
    } else {
      if (!Vue.i18n.locale()) {
        Vue.i18n.set(this.companyData.defaultLanguage);
      }
    }
  },
  mounted() {
    if (this.$route.params.firstRun == true) {
      this.showModal();
    }
  },
  beforeMount() {
    var payload;
    if (this.$route.query.apikey) {
      var mid;
      if (this.$route.query.mid) {
        mid = this.$route.query.mid;
      } else {
        mid = 0;
      }
      payload = {
        apikey: this.$route.query.apikey,
        tableNumber: this.$route.query.table,
        mid: mid,
      };
      this.$store.dispatch("setStore", payload).then(() => {
        this.initLang();
        window.location.href = "/";
      });
    } else if (this.inStore) {
      payload = {
        apikey: this.companyData.apiKey,
        tableNumber: this.companyData.tableNumber,
        mid: this.companyData.mid,
      };
      this.$store.dispatch("setStore", payload);
    } else {
      if (this.$route.query.lang) {
        this.$router.push({
          name: "stores",
          query: { lang: this.$route.query.lang },
        });
      } else {
        this.$router.push({
          name: "stores",
        });
      }
    }
  },
};
</script>
