const state = {
  bill: [],
};

const mutations = {
  SET_BILL(state, billData) {
    localStorage.removeItem("bill");
    localStorage.setItem("bill", JSON.stringify(billData));
    state.bill = billData;
  },

  CLEAR_BILL() {
    state.bill = 0;
    localStorage.removeItem("bill");
  },
};

export default {
  state,
  mutations,
};
