<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="navPill_wrapper text-center mb-3">
          <div v-dragscroll.x class="navPill_container">
            <div
              class="navPill t1 text-center mb-1"
              :class="item === groupName ? 'active' : ''"
              v-for="item in itemGroups"
              :key="item"
              :groupName="groupName"
              @click="updateProducts(item)"
            >
              {{ productGroupName(item) | translate }}
            </div>
            <div
              class="navPill t1 text-center mb-1"
              :class="groupName === 'all' ? 'active' : ''"
              @click="updateProducts('all')"
            >
              {{ "Alles" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-8 px-3 m-0">
        <div v-if="groupName === 'all'">
          <h3><strong>{{ "Alles" | translate }}</strong></h3>
          <transition name="slide-fade" mode="out-in">
            <div>
              <div v-for="item in itemGroups" :key="item" class="m-0 p-0">
                <h4>{{ productGroupName(item) | translate }}</h4>
                <productGroup :group="productGroup(item)" :key="componentKey" />
              </div>
            </div>
          </transition>
        </div>
        <div v-else>
          <h3><strong>{{ productGroupName(groupName) | translate }}</strong></h3>
          <transition name="slide-fade" mode="out-in">
            <productGroup
              :group="productGroup(groupName)"
              :key="componentKey"
            />
          </transition>
        </div>
      </div>
      <div class="col-xl-4 px-1 d-none d-xl-block">
        <div v-if="itemsQuantity > 0">
          <BaseIcon
            name="shopping-cart-full"
            width="25"
            height="25"
            class="my-1 mr-2"
            style="float: right"
          />
        </div>
        <div v-else>
          <BaseIcon
            name="shopping-cart"
            width="25"
            height="25"
            class="my-1 mr-2"
            style="float: right"
          />
        </div>
        <h3 class="text-right">{{ "Warenkorb" | translate }}</h3>
        <ShoppingCart />
      </div>
    </div>
  </div>
</template>

<script>
import productGroup from "@/components/productGroup.vue";
import ShoppingCart from "@/components/shopping-cart/ShoppingCart";
import { mapState, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "app-orderview",
  components: {
    productGroup,
    ShoppingCart,
  },
  data() {
    return {
      componentKey: 0,
      groupName: "all",
      scroll: true,
    };
  },
  created() {
    if (this.$route.params.grpNme) {
      this.groupName = this.$route.params.grpNme;
    }
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },

  computed: {
    ...mapState({
      products: (state) => state.products.all,
    }),
    ...mapGetters(["itemsQuantity","companyData", "cartProducts"]),

    itemGroups() {
      var pdts = this.products;
      var prodCopy = pdts.map((a) => a);
      var spdts = prodCopy.sort((a, b) => {
        return a.groupSort - b.groupSort;
      });
      return (pdts = [...new Set(spdts.map(({ groupName }) => groupName))]);
    },

    tableNumber() {
      return this.companyData.tableNumber;
    },
  },
  methods: {
    productGroup(m) {
      return this.products.filter((item) => item.groupName === m);
    },
    productGroupName(m) {
      var lang = Vue.i18n.locale();
      var index = this.companyData.languages.findIndex((x) => x === lang);
      var gn = this.products.find((item) => item.groupName === m);
      if (index == 1 && gn.groupName1) {
        return gn.groupName1;
      } else if (index == 2 && gn.groupName2) {
        return gn.groupName2;
      } else if (index == 3 && gn.groupName3) {
        return gn.groupName3;
      } else {
        return m;
      }
    },
    updateProducts(val) {
      this.groupName = val;
      this.componentKey += 1;
    },
    scroll_left() {
      let content = document.querySelector(".navPill_container");
      content.scrollLeft -= 100;
    },
    scroll_right() {
      let content = document.querySelector(".navPill_container");
      content.scrollLeft += 100;
    },
  },
};
</script>

<style scoped>
.grtxt {
  padding-left: 5px;
  width: 152px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
