<template>
  <div>
    <h2 class="text-center">
      {{ message | translate }}
    </h2>
    <div v-if="showQR">
      <div class="text-center pb-3">
        <button class="btn btn-lg btn-success mx-0" @click="startScan()">
          {{ "ScanStart" | translate }}
        </button>
      </div>
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-10">
          <b-form v-show="scanwindow" @submit.prevent="submitApiKey">
            <div class="panel t1 p-2 mb-4">
              <div class="scanWindow">
                <qrcode-stream @decode="onDecode" />
              </div>
            </div>
          </b-form>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  name: "app-storelist",
  props: {
    config: Object,
  },
  data() {
    return {
      result: null,
      scanwindow: false,
      message: "ScanNow",
      showQR: true,
    };
  },
  methods: {
    startScan() {
      this.scanwindow = true;
    },
    onDecode(result) {
      var payload;
      if (result.startsWith("http")) {
        var s1 = result.split("?");
        var s2 = s1[1];
        var s3 = s2.split("&");
        var key = s3[0].replace("apikey=", "");
        var tbn, mid, qrIdent1;
        if (s3.length > 1) {
          tbn = s3[1].replace("table=", "");
          if (tbn.includes("_")) {
            qrIdent1 = false;
          } else {
            qrIdent1 = true;
          }
        } else {
          tbn = 0;
        }
        if (s3.length > 2) {
          mid = s3[2].replace("mid=", "");
        } else {
          mid = 0;
        }
        payload = {
          apikey: key,
          tableNumber: tbn,
          mid: mid,
          confQR: qrIdent1,
        };
        this.$store
          .dispatch("setStore", payload)
          .then(
            this.$router.push({ name: "order", params: { firstRun: true } })
          );
      } else {
        var qrIdent2,
          arr = result.split(",");
        if (arr[2]) {
          mid = arr[2];
        } else {
          mid = 0;
        }
        if (arr[1].includes("_")) {
          qrIdent2 = false;
        } else {
          qrIdent2 = true;
        }
        payload = {
          apikey: arr[0],
          tableNumber: arr[1],
          mid: mid,
          confQR: qrIdent2,
        };
        this.$store
          .dispatch("setStore", payload)
          .then(this.$router.push({ name: "order" }));
      }
    },
  },
  created() {
    Vue.i18n.set(this.$config.default_lang);
    if (this.$route.query.msg) {
      this.showQR = this.$config.show_final_scan;
      this.message = this.$route.query.msg;
    }else{
      this.showQR = true;
      this.message = "ScanNow";
    }
    if (this.$route.query.lang) {
      axios
        .get("/locale/" + this.$route.query.lang + ".json?v=" + Date.now() + "")
        .then((response) => {
          Vue.i18n.set(this.$route.query.lang);
          Vue.i18n.add(this.$route.query.lang, response.data);
        });
    } else {
      axios
        .get(
          "/locale/" + this.$config.default_lang + ".json?v=" + Date.now() + ""
        )
        .then((response) => {
          Vue.i18n.set(this.$config.default_lang);
          Vue.i18n.add(this.$config.default_lang, response.data);
        });
    }
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.scanWindow {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border: 1px solid #cccccc;
  background-color: #3e3c49;
}
</style>
