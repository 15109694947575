const state = {
  order: [],
};

const mutations = {
  SET_ORDER(state, orderData) {
    localStorage.removeItem("order");
    localStorage.setItem("order", JSON.stringify(orderData));
    state.order = orderData;
  },

  CLEAR_ORDER() {
    state.order = 0;
    localStorage.removeItem("order");
  },
};

export default {
  state,
  mutations,
};
