<template>
  <div class="header_button">
    <span v-if="total > 0" @click="goToCart()" >
      <span v-if="itemsQuantity > 0">
        <BaseIcon
          name="shopping-cart-full"
          width="23"
          height="23"
          class="header_icon mr-1"
        />
      </span>
      <span v-else>
        <BaseIcon
          name="shopping-cart"
          width="23"
          height="23"
          class="header_icon mr-1"
        />
      </span>
      <span class="itemsQuantity">({{ itemsQuantity }})</span>
      <!--<span class="itemsTotal">{{ total | currency(cSymb) }}</span>-->
    </span>
    <span v-for="(item, index) in companyData.languages" :key="index">
      <span class="langSelect" v-if="item != '' && item != get_lang()" @click="set_lang(item)">
        <img
        v-if="item != '' && item != get_lang()"
        :src="'/assets/img/' + item + '.png'"
        :width="30"
        tabindex="-1"
        @click="set_lang(item)"
      />
      </span>
    </span>

  </div>
</template>


<script>
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "ShoppingCart",
  computed: {
    ...mapGetters(["itemsQuantity", "taxes", "total", "companyData"]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
  },
  methods: {
    clearStore() {
      window.localStorage.clear();
      window.location.href =
        "/stores/?lang=" + this.companyData.defaultLanguage;
    },
    get_lang() {
      return Vue.i18n.locale();
    },
    set_lang(value) {
      Vue.i18n.set(value);
    },
    goToCart(){
      this.$router.push({ path: "/cart" });
    }
  },
};
</script>
<style scoped></style>
