<template>
  <div>
    <div v-if="itemsQuantity > 0">
      <BaseIcon
        name="shopping-cart-full"
        width="25"
        height="25"
        class="my-1 mr-2"
        style="float: right"
      />
    </div>
    <div v-else>
      <BaseIcon
        name="shopping-cart"
        width="25"
        height="25"
        class="my-1 mr-2"
        style="float: right"
      />
    </div>
    <h3>{{ "Warenkorb" | translate }}</h3>
    <div class="row">
      <div class="col-lg-12 px-1 py-1">
        <ShoppingCart />
      </div>
    </div>
  </div>
</template>

<script>
import ShoppingCart from "@/components/shopping-cart/ShoppingCart";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "app-cart",
  components: {
    ShoppingCart,
  },
  props: {
    groupName: {
      type: String,
    },
    productList: {
      type: Array,
    },
  },
  methods: {
    ...mapActions({
      removeAllFromCart: "removeAllFromCart",
    }),
  },
  watch: {},
  computed: {
    ...mapGetters(["itemsQuantity"]),
    productGroup() {
      return this.productList;
    },
  },
};
</script>
