import DataService from "@/services/DataService.js";

export const getProducts = ({ commit }, credentials) => {
  DataService.getAllProducts(credentials.key).then((response) => {
    commit("RECEIVE_PRODUCTS", response.data);
  });
};

export const addToCart = ({ commit }, cartItem) => {
  commit("ADD_TO_CART", cartItem);
};

export const replaceInCart = ({ commit }, cartItem) => {
  commit("REPLACE_IN_CART", cartItem);
};

export const removeFromCart = ({ commit }, cartItem) => {
  commit("REMOVE_FROM_CART", cartItem);
};

export const setOrder = ({ commit }, orderData) => {
  commit("SET_ORDER", orderData);
};

export const clearOrder = ({ commit }) => {
  commit("CLEAR_ORDER");
};

export const setBill = ({ commit }, billData) => {
  commit("SET_BILL", billData);
};

export const clearBill = ({ commit }) => {
  commit("CLEAR_BILL");
};

export const removeAllFromCart = ({ commit }) => {
  commit("REMOVE_ALL_FROM_CART");
};

export const clearStore = ({ commit }) => {
  commit("CLEAR_STORE");
};

export const setStore = ({ commit }, credentials) => {
  return DataService.getStore(credentials.apikey).then((response) => {
    response.data.tableNumber = credentials.tableNumber;
    response.data.mid = credentials.mid;
    commit("SET_STORE", response.data);
  });
};
